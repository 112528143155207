<script setup lang="ts">
    import { Listbox, ListboxButton } from '@headlessui/vue';
    console.log('WoomFormField.vue');

    const props = defineProps({
        required: {
            type: Boolean,
            default: false,
        },
        floatingLabel: {
            type: Boolean,
            default: false,
        },
        forId: {
            type: String,
            // TODO: uncomment when data is available
            // required: true
        },
        label: {
            type: String,
            default: '',
        },
        labelClass: {
            type: String,
            default: '',
        },
        layout: {
            type: String,
            default: '',
        },
        autocomplete: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String,
            default: '',
        },
    });
    const slots = useSlots();
    const labelVisible = ref(true);

    const fieldClass = computed(() => {
        return {
            'field--required': props.required,
            'field--optional': !props.required,
            'field--show-floating-label': props.floatingLabel,
            'field--half': props.layout === 'half',
            'field--third': props.layout === 'third',
            'field--error': props.error !== '',
        };
    });

    const labelClasses = computed(() => {
        const ret = {
            'field__label-visible': labelVisible.value,
        };
        if (props.labelClass && props.labelClass !== '') {
            // @ts-ignore
            ret[props.labelClass] = true;
        }
        return ret;
    });
</script>

<template>
    <div
        :class="fieldClass"
        class="field">
        <div
            v-if="slots.default"
            class="field__input-wrapper">
            <div
                v-if="false"
                :class="labelClass"
                class="field__label">
                <span>label</span>
            </div>

            <slot></slot>
        </div>
        <slot name="full"></slot>
        <p class="field__message field__message--error">error message</p>
    </div>
</template>

<!--     
    <template>
  <div
    :class="fieldClass"
    :data-autocomplete-field-container="autocomplete"
    class="field"
  >
    <div v-if="$slots.default" class="field__input-wrapper">
      <div
        v-if="label !== ''"
        :class="labelClasses"
        :for="forId"
        class="field__label"
      >
        {{ label }}
      </div>
      <slot />
    </div>
    <slot name="full" />
    <p
      :id="'error-for-' + forId"
      class="field__message field__message--error"
    >
      <template v-if="error !== ''">
        {{ error }}
      </template>
    </p>
  </div>
</template>
 -->

<style>
    .field {
        width: 100%;
        float: left;
    }

    .field p {
        margin: 0;
        line-height: 1.5em;
    }

    .field p .field__message {
        line-height: 1.3em;
        margin: 0.5714285714em 0 0.2857142857em;
    }
    .field__input-wrapper {
        position: relative;
    }
    .field__label {
        text-align: left;
        margin: 0.5em 0;
    }

    .floating-labels .field__label {
        display: inline-block;
        font-size: 0.8571em;
        font-weight: normal;
        position: absolute;
        top: 0;
        margin-top: 0.4286em;
        margin-left: calc(1px + 0.6em);
        padding: 0 0.2em;
        z-index: 1;
        user-select: none;
        transform: translateY(-1em);
        pointer-events: none;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        background-color: var(--color-white);
        opacity: 1;
    }
    .floating-labels .field--show-floating-label .field__label {
        transform: none;
        opacity: 1;
    }
    .floating-labels .field--show-floating-label .field__input {
        padding-top: 1.5em;
        padding-bottom: 0.3571428571em;
    }
    .field__label--visible {
        display: block;
    }
    .animate-floating-labels .field__label {
        transition: all 0.2s ease-out;
    }

    .field__caret {
        border-left: 1px rgba(179, 179, 179, 0.5) solid;
    }
    button,
    input,
    optgroup,
    select,
    textarea {
        color: inherit;
        font: inherit;
        margin: 0;
        padding: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -webkit-font-smoothing: inherit;
        border: none;
        background: transparent;
        line-height: normal;
        font-size: 14px;
    }

    .field__input {
        border: 1px transparent solid;
        background-clip: padding-box;
        border-radius: 5px;
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        padding: 0.9285714286em 0.7857142857em;
        min-height: 56px;
        word-break: normal;
        line-height: inherit;
        background-color: var(--color-white);
        color: var(--color-medium-grey);
        border-color: var(--color-light-grey);
    }

    .field--error .field__input {
        border-color: #e32c2b;
        -webkit-box-shadow: 0 0 0 1px #e32c2b;
        box-shadow: 0 0 0 1px #e32c2b;
    }

    .field--error .field__message--error {
        display: block;
    }

    .field__message--error {
        display: none;
        color: #e32c2b;
    }

    @media only screen and ((min-width: 728px )and (min-height: 10px)) {
        .field__label {
            display: none !important;
        }
        .floating-labels .field--half {
            width: 50%;
        }
        .floating-labels .field--third {
            width: 33.333333%;
        }
    }
</style>

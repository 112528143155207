export const ProductAvailability = {
    LOCKED: 'LOCKED',
    AVAILABLE: 'AVAILABLE',
    PREORDER: 'PREORDER',
    OUT_OF_STOCK: 'OUT_OF_STOCK',
    NOTIFY_ME: 'NOTIFY_ME',
    NOT_IN_THIS_REGION: 'NOT_IN_THIS_REGION',
    COMING_SOON: 'COMINGSOON',
};

export const NotificationType = {
    ERROR: 'ERROR',
    INFO: 'INFO',
};

export const appEvents = {
    click: 'click',
};

<script setup lang="ts">
    import { IncomingHttpHeaders } from 'http';
    import { ApolloError, ApolloQueryResult } from '@apollo/client';
    import { LanguageSwitcherQuery, useLanguageSwitcherLazyQuery, useLanguageSwitcherQuery, WoomRegion } from '~~/graphql/generated';
    import { NotificationType } from '~~/common/constants';

    import { LanguageSwitchClickEventPayload } from '~~/common/types';
    import { useAppStore } from '~~/store/app';
    import { ImageType } from '~~/common/types/nestedGraphqlTypes';
    import { awaitableGQLLazyQuery } from '~/graphql/graphql-helpers';

    type LanguageSwitcherPageStateType = {
        headers: IncomingHttpHeaders;
        title: string | undefined;
        copy: string | undefined;
        image: ImageType;
        regions: WoomRegion[];
        seo: {
            title?: string | null | undefined;
            description?: string | null | undefined;
            image?: ImageType;
        };
        country: string;
        language: string;
        langs: string[];
    };

    const pageStateDefaultValue: LanguageSwitcherPageStateType = {
        headers: {},
        title: '',
        copy: '',
        image: null,
        regions: [],
        seo: {
            title: '',
            description: '',
            image: null,
        },
        country: '', // v-model on language-switch component?
        language: '',
        langs: [],
    };

    console.log('LanguageSwitcher.vue');

    const appStore = useAppStore();

    const route = useRoute();

    const baseUrl = computed(() => appStore.state.baseUrl);
    const preview = computed(() => appStore.state.preview);

    const pageState = useState<LanguageSwitcherPageStateType>('language-switcher-page-state', () => {
        return pageStateDefaultValue;
    });

    const addLang = (newLang: string) => {
        console.log('addLang', newLang);
        if (!pageState.value.langs.includes(newLang)) {
            pageState.value.langs.push(newLang);
        }
    };

    const langParam = route.params?.lang;

    console.log('langParam', langParam);

    if (langParam) {
        const langParamString = Array.isArray(langParam) ? langParam[0] : langParam;
        addLang(langParamString);
    }

    let previewWIP = (route.query?.preview || 'false') === 'true';

    if (appStore.state.preview) {
        previewWIP = true;
    }

    if (previewWIP) {
        appStore.actions.enqueueNotification({
            type: NotificationType.INFO,
            message: 'The Woom Site is in PREVIEW mode',
            sticky: true,
        });
    }

    const requestEvent = useRequestEvent();

    if (process.server) {
        const headers = requestEvent.node.req.headers;
        if (headers) {
            pageState.value.headers = headers;
            const acceptLanguageHeader = headers['accept-language'];
            //console.log('acceptLanguageHeader', acceptLanguageHeader);
            if (acceptLanguageHeader) {
                for (const lang of acceptLanguageHeader.split(',')) {
                    addLang(lang || 'en');
                }
            }
        }
    }

    addLang('en');

    const lang = computed(() => pageState.value.langs.join(','));

    const fetchData = async () => {
        const languageSwitcherQuery = useLanguageSwitcherLazyQuery({
            locale: lang.value, // 'en',
            preview: previewWIP,
        });

        try {
            const result = await awaitableGQLLazyQuery(languageSwitcherQuery);
            console.log('languageSwitcherQuery result', result);
            if (result.loading) {
                return;
            }

            const pageData = result.data.languageSwitcher;

            if (pageData) {
                // @ts-ignore
                pageState.value.regions = pageData.sequence
                    .map((p) => {
                        return pageData.regions.find((n) => n.id === p);
                    })
                    .filter((p) => !!p);

                pageState.value.title = pageData.title || undefined;

                pageState.value.copy = pageData.copy || undefined;

                if (pageData.seo) {
                    pageState.value.seo = pageData.seo;
                }

                pageState.value.image = pageData.image;

                pageState.value.language = pageData?.system?.language || 'en';

                // try to set the language
                await appStore.actions.setLangCountry(pageState.value.language);
            }
        } catch (err) {
            console.log('error', err);
        }
    };

    if (process.client) {
        fetchData();
    }

    const handleClick = async (data: LanguageSwitchClickEventPayload) => {
        // we need to set the langCountry before we push the router
        await appStore.actions.setLangCountry(`${data.language}_${data.country.toUpperCase()}`);

        if (preview.value) {
            document.location = `/${data.language}_${data.country.toUpperCase()}?preview=true`;
        } else {
            document.location = `/${data.language}_${data.country.toUpperCase()}`;
        }
    };

    // TODO-FROM-OLD-SITE

    /*
                  head() {
                      if (process.server) {
                        // console.log('og url', `${this.baseUrl}${this.$route.fullPath}`)
                        return {
                          title: this.seo && this.seo.title ? this.seo.title : 'woom.com',
                          htmlAttrs: {
                            lang: this.language,
                          },
                          meta: [
                            {
                              hid: 'description',
                              name: 'description',
                              content: this.seo.description || '',
                            },
                            {
                              hid: 'og_title',
                              name: 'og:title',
                              content: this.seo.title || '',
                            },
                            {
                              hid: 'og_description',
                              name: 'og:description',
                              content: this.seo.description || '',
                            },
                            {
                              hid: 'og_image',
                              name: 'og:image',
                              content:
                                this.seo.image && this.seo.image.data && this.seo.image.data.src
                                  ? this.seo.image.data.src
                                  : '',
                            },
                            {
                              hid: 'og_url',
                              name: 'og:url',
                              content: `${this.baseUrl}${this.$route.fullPath}`,
                            },
                          ],
                        }
                      } else {
                        return {
                          title: this.seo && this.seo.title ? this.seo.title : 'woom.com',
                          htmlAttrs: {
                            lang: this.language,
                          },
                        }
                      }
                    }
                  */
</script>

<template>
    <div style="display: flex; width: 100vw; height: 100vh; align-items: center; justify-content: center">
        <!-- <mol-drawer></mol-drawer> -->
        <!-- <mol-header></mol-header> -->
        <OrgLanguageSwitch
            :title="pageState.title"
            :copy="pageState.copy"
            :country="pageState.country"
            :language="pageState.language"
            :countries="pageState.regions"
            @click="handleClick" />
    </div>
</template>

<!-- <template>
    <div class="language-switcher">
      <atm-logo v-once class="landing-logo" />
      <mol-asset
        v-if="image && image.data"
        :data="image"
        width="100%"
        height="100"
        class="landing-visual"
        preset="3to2"
      />
      <div class="language-container">
        <language-switch
          :title="title"
          :copy="copy"
          :country="country"
          :language="language"
          :countries="regions"
          @click="handleClick"
        />
      </div>
    </div>
  </template>
   -->
<style>
    .language-switcher {
        min-height: 100vh;
        max-width: 100vw;
        overflow-x: hidden;
        display: flex;
    }
    .landing-visual {
        @apply flex-auto;
    }
    @media only screen and (max-width: 728px) {
        .landing-visual {
            @apply hidden;
        }
    }

    .landing-logo {
        @apply absolute top-5 left-5 z-10;
        --logo-color: var(--color-woom-red);
    }
    .language-container {
        display: flex;
        flex: 0 0 auto;
        justify-content: center;
        align-items: center;
        padding: 60px 20px;
        max-width: 400px;
        min-width: 384px;
        width: 100%;
        margin: auto;
    }
    @media only screen and (max-width: 728px) {
        .language-container {
            @apply max-w-full min-w-full;
        }
    }
</style>

<script setup lang="ts">
    import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue';
    const props = defineProps({
        value: {
            type: String,
            default: null,
        },
    });
    const content = ref(props.value);

    const multiple = computed(() => {
        // TODO: update from old site
        //   return get(this.config, 'multiple', false)
        return false;
    });

    const langCountry = [
        {
            id: 1,
            country: 'International',
            lang: [],
        },
        { id: 2, country: 'Switzerland', lang: ['Deutsch', 'Francis', 'Italiano'] },
        { id: 3, country: 'United State', lang: ['English'] },
    ];

    const selectedItem = ref(langCountry[0]);
</script>

<template>
    <atm-form-field>
        <div
            class="mobile-only"
            :class="[multiple ? 'multiple-selection' : '']">
            <div class="flex-flex-col py-2">
                <Listbox
                    v-model="selectedItem"
                    as="div">
                    <div class="flex relative border border-woomGray-medium text-woom-black rounded h-14 py-4 px-6 gap-2">
                        <span
                            class="absolute text-base text-gray-500 dark:text-gray-400 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-woom-white dark:bg-gray-900 px-2 left-1">
                            floating label
                        </span>
                    </div>
                </Listbox>
            </div>
        </div>
        <div class="non-mobile">
            <!-- <div class="field--show-floating-label select-input theme--light white py-3"> -->
            <div class="py-3 flex flex-col">
                <Listbox
                    v-model="selectedItem"
                    as="div">
                    <div class="relative border bg-gray-200 border-gray-400 text-gray-900 h-14 rounded py-4 px-6 flex gap-2">
                        <span
                            class="absolute text-base text-gray-500 dark:text-gray-400 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-woom-white dark:bg-gray-900 px-2 left-1">
                            floating label
                        </span>

                        <div class="">
                            <ListboxButton class="relative grow flex">
                                <span class="block truncate">{{ selectedItem.country }}</span>
                            </ListboxButton>

                            <transition
                                leave-active-class="transition ease-in duration-100"
                                leave-from-class="opacity-100"
                                leave-to-class="opacity-0">
                                <ListboxOptions
                                    class="absolute z-20 w-full left-0 top-0 bg-woom-white rounded-md text-lg ring-1 ring-woom-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                    <template
                                        v-for="lc in langCountry"
                                        :key="lc.country">
                                        <ListboxOption
                                            v-slot="{ active, selected }"
                                            :value="lc"
                                            as="template">
                                            <li
                                                :class="[
                                                    active ? 'bg-woomGray-medium active:bg-woomGray-medium text-amber-900 ' : 'text-gray-900',
                                                    'cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-woomGray-lighter',
                                                ]">
                                                <div class="flex items-center">
                                                    <span :class="[selected ? 'text-lg font-normal' : 'font-normal text-lg', 'block truncate']">
                                                        {{ lc.country }}
                                                    </span>
                                                </div>
                                            </li>
                                        </ListboxOption>
                                    </template>
                                </ListboxOptions>
                            </transition>
                        </div>
                    </div>
                </Listbox>
            </div>
        </div>
    </atm-form-field>
</template>

<!--  <atm-form-field class="field--show-floating-label select-input theme--light white">
        <div class="non-mobile">field</div>
    </atm-form-field> -->
<style>
    .field__input--select {
        padding-right: 2.0714285714em;
        white-space: nowrap;
    }

    /*
    TODO: Further look into the element 
            .v-menu__content
        &::-webkit-scrollbar
            -webkit-appearance: none
        &::-webkit-scrollbar-track
            background-color: white
            box-shadow: none
        &::-webkit-scrollbar-thumb
            background-color: darkgrey
            outline: 1px solid slategrey 
    */

    .mobile-only {
        display: block;
        margin-bottom: 24px;
    }
    .mobile-only select {
        border: 1px solid rgba(34, 34, 34, 0.41);
        outline: none;
    }

    .non-mobile {
        display: none;
    }

    .field__caret {
        display: block;
        width: 2.1428571429em;
        height: 43%;
        pointer-events: none;
        position: absolute;
        top: 50%;
        right: 0;
        -webkit-transform: translate(0%, -50%);
        transform: translate(0%, -50%);
    }
    .field__caret .multiple-selection {
        display: none;
    }

    .field__caret .field__caret-svg {
        position: absolute;
        margin-left: -2px;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    .field__caret .icon-svg--size-10 {
        width: 10px;
        height: 10px;
    }

    /* .field.field--error .v-label  */
    .field.field--error .v-label {
        color: #e32c2b;
    }

    .field.field--error .v-text-field--outlined.v-input--has-state fieldset {
        border: 1px solid #e32c2b;
    }

    .field p.field__message.field__message--error {
        margin: -16px 0 0 11px;
        font-size: 14px;
    }

    @media only screen and (min-width: 728px) and (min-height: 10px) {
        .mobile-only {
            display: none;
        }
        .non-mobile {
            display: block;
        }
    }
</style>

<template>
    <div class="region-selector">
        <h3>{{ title }}</h3>
        <div v-html="copy"></div>

        <!-- //TODO-FROM-OLD-SITE 
            <rich-text-format
            :value="copy"
            class="welcome-copy" /> -->
        <div class="country-lang floating-labels animate-floating-labels display-table">
            <atm-form-select-field
                id="country-select"
                v-model="pageState.selectedCountry"
                :label="countryLabel"
                :config="{ options: countryOptions, placeholder: '--' }" />

            <atm-form-select-field
                id="country-select"
                v-model="pageState.selectedLanguage"
                :label="languageLabel"
                :config="{
                    options: languageOptions,
                    enabled: !!pageState.selectedCountry && languageOptions && languageOptions.length > 1,
                    placeholder: '--',
                }" />

            <atm-woom-button
                :disabled="!pageState.selectedLanguage || !pageState.selectedCountry"
                woom
                block
                dark
                @click="handleClick">
                {{ $t('landing.selector.button') }}
            </atm-woom-button>

            <!-- <w-button
                :disabled="!pageState.selectedLanguage || !pageState.selectedCountry"
                block
                @click="handleClick"
                class="black white--text"
                >{{ $t('landing.selector.button') }}</w-button
            > -->
        </div>
    </div>
</template>
<script setup lang="ts">
    import { useI18n } from 'vue-i18n';

    import { getNativeName } from '~~/common/utils/languages';
    import { WoomRegion } from '~~/graphql/generated';
    import { appEvents } from '~~/common/constants';
    import { LanguageSwitchClickEventPayload } from '~~/common/types';
    import { useAppStore } from '~~/store/app';

    const i18n = useI18n();

    type PageStateType = {
        selectedCountry: string | null;
        selectedLanguage: string | null;
    };

    const props = defineProps({
        title: {
            type: String,
            default: '',
        },
        copy: {
            type: String,
            default: '',
        },
        language: {
            type: String,
            default: '',
        },
        country: {
            type: String,
            default: '',
        },
        countries: {
            type: Array<WoomRegion>,
            required: true,
        },
    });

    const pageStateDefaultValue: PageStateType = {
        selectedCountry: props.country || null,
        selectedLanguage: props.language || null,
    };

    const pageState = useState<PageStateType>('language-switch-state', () => {
        return pageStateDefaultValue;
    });

    const appStore = useAppStore();

    const baseUrl = computed(() => appStore.state.baseUrl);
    const preview = computed(() => appStore.state.preview);

    const countryOptions = computed(() => {
        if (props.countries) {
            return props.countries.map((c) => {
                return {
                    label: c.title,
                    value: c.code,
                    selected: c.code === pageState.value.selectedCountry,
                };
            });
        }
        return [];
    });

    const languageOptions = computed(() => {
        if (pageState.value.selectedCountry) {
            const country = props.countries.find(({ code }) => code === pageState.value.selectedCountry);
            if (country && country.languages) {
                return country.languages.map((l) => {
                    let code = l;
                    if (code.indexOf('_') > 0) {
                        const s = code.split('_');
                        code = `${s[0]}-${s[1].toUpperCase()}`;
                    }
                    return {
                        label: getNativeName(code),
                        value: l,
                    };
                });
            }
        }
        return [];
    });

    watch(
        () => languageOptions.value,
        () => {
            if (languageOptions.value && languageOptions.value.length) {
                if (
                    props.language &&
                    languageOptions.value
                        // [[NOTE]] Original code did not have this line - attempted to compare object to string?
                        .map((op) => op.value)
                        .includes(props.language)
                ) {
                    pageState.value.selectedLanguage = props.language;
                } else {
                    pageState.value.selectedLanguage = languageOptions.value[0].value;
                }
            }
        },
    );

    // return this.$store.getters['i18n/t']('landing.selector.country')
    const countryLabel = computed(() => i18n.t('landing.selector.country'));

    // return this.$store.getters['i18n/t']('landing.selector.language')
    const languageLabel = computed(() => i18n.t('landing.selector.language'));

    // const emit = defineEmits(['click']);

    const emit = defineEmits<{
        (event: typeof appEvents.click, payload: LanguageSwitchClickEventPayload): void;
    }>();

    const handleClick = () => {
        if (pageState.value.selectedCountry && pageState.value.selectedLanguage) {
            // just check if we have an alternative url
            const country = props.countries.find((c) => c.code === pageState.value.selectedCountry);
            if (country) {
                if (country.url && country.url.indexOf('http') === 0) {
                    document.location = country.url;
                } else {
                    emit(appEvents.click, {
                        country: pageState.value.selectedCountry,
                        language: pageState.value.selectedLanguage,
                    });
                }
            }
        }
    };
</script>

<style>
    .region-selector {
        max-width: 384px;
        margin: auto;
    }

    .region-selector h3 {
        white-space: pre-line;
    }

    .region-selector h3 + .welcome-copy {
        margin-top: 16px;
    }

    .region-selector .country-lang {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
    }

    .region-selector .field {
        padding: 0;
    }

    .region-selector .woom-button {
        @apply h-12 rounded items-center justify-center;
        width: 100% !important;
        max-width: 100% !important;
        background-color: var(--color-black);
        color: var(--color-white);
    }

    @media only screen and (max-width: 728px) {
        .region-selector {
            max-width: inherit;
            min-width: inherit;
        }
    }
</style>
